import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Porcoes = () => (
  <>
    <SEO title="Porções" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Porções</h1>
      </Slide>

      <div className="menu-sizes">
        <p>Meia</p>
        <p>Inteira</p>
      </div>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Mandioca</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>

            <h4 className="item-price">28,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Polenta</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">20,00</h4>

            <h4 className="item-price">28,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Fritas</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">22,00</h4>

            <h4 className="item-price">32,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Fritas com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">28,00</h4>

            <h4 className="item-price">34,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Fritas com Queijo e Bacon</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">32,00</h4>

            <h4 className="item-price">40,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Fritas com Bacon e Cheddar</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">38,00</h4>

            <h4 className="item-price">46,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Polenta com Queijo</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">28,00</h4>

            <h4 className="item-price">34,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Contra Filé Acebolado</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">75,00</h4>

            <h4 className="item-price">95,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Frango à Passarinho</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">37,00</h4>

            <h4 className="item-price">47,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Tilápia</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">52,00</h4>

            <h4 className="item-price">65,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Bolinho de Carne (12 unid.)</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">48,00</h4>

            <h4 className="item-price">60,00</h4>
          </div>
        </div>

        <p className="item-desc">Recheado com Queijo do Mineiro.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Coxinha c/ Catupiry (8 unid.)</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">26,00</h4>

            <h4 className="item-price">52,00</h4>
          </div>
        </div>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">Provolone à Milanesa (400g)</h4>

          <div className="item-price-wrapper">
            <p className="item-price"></p>

            <h4 className="item-price">55,00</h4>
          </div>
        </div>
      </Item>
    </Menu>
  </>
);

export default Porcoes;
